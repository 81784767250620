import HeroButton from '@components/PageComponents/Hero/HeroButton';
import { Section, SectionContainer } from '@components/Section';
import Layout from '@components/Layout/Layout';
import Img from '@components/Img';

import Text from '@hiredigital/ui/Text/Text';
import Header from '@components/Header/DefaultHeader';

import Styles from '../styles/error.module.scss';

const Error = (props) => {
  const head = {
    title: `Page Not Found | Hire Digital`,
    meta: [
      {
        name: 'description',
        content:
          "The page you're looking for no longer exists. Please contact us if this is a mistake.",
      },
    ],
  };

  return (
    <Layout header={Header} pageHead={head} className={Styles.body} darkFooter>
      <SectionContainer className={Styles.sectionContainer}>
        <div className={Styles.imageContainer}>
          <Img
            src={`/img/hero/office.webp`}
            alt='image'
            className={Styles.imageItem}
            wrapperClassName={Styles.image}
          />
        </div>

        <Section className={Styles.container}>
          <div className={Styles.text}>
            <Text
              element='h1'
              className={Styles.headline}
              size={Text.Size.HEADLINE}
              color={Text.Color.WHITE}>
              {'Page Not Found'}
            </Text>
            <Text className={Styles.subheadline} size={Text.Size.H3} color={Text.Color.WHITE}>
              {
                "The page you're looking for no longer exists. Please contact us if this is a mistake."
              }
            </Text>
            <HeroButton
              className={Styles.button}
              type={HeroButton.Type.WHITE}
              to={`/`}
              title='Back Home'>{`Back to Home`}</HeroButton>
            <HeroButton
              id='intercom-chat'
              href='#'
              className={Styles.button}
              type={HeroButton.Type.WHITE_OUTLINE}
              title='Get Help'>{`Get Help`}</HeroButton>
          </div>
        </Section>
      </SectionContainer>
    </Layout>
  );
};

export default Error;
